import moment from "moment";
import { Avatar, AvatarGroup, HStack, Link, Text } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import _ from "lodash";
import { CustomBadge, CustomerLogo } from "components";
import { translator } from "lib";

const defaultColumns = [
  {
    accessor: "nid",
    title: "NID",
    isVisible: true,
    render: ({ _id, nid }) => (
      <Link as={RouterLink} to={`/meetings/edit/${_id}`}>
        {nid}
      </Link>
    ),
    exporter: ({ nid }) => nid,
  },
  {
    accessor: "status",
    title: "Status",
    isVisible: true,
    render: ({ status }) => <CustomBadge.Meetings.Status status={status} />,
    exporter: ({ status }) => translator(status),
  },
  {
    accessor: "title",
    title: "Título",
    isVisible: false,
    render: ({ _id, title }) => (
      <Link as={RouterLink} to={`/meetings/edit/${_id}`}>
        {title}
      </Link>
    ),
    exporter: ({ title }) => title,
  },
  {
    accessor: "date",
    title: "Data",
    isVisible: true,
    isSortable: false,
    formatter: ({ startDate }) => moment(startDate).format("DD/MM/YYYY"),
  },
  {
    accessor: "startDate",
    title: "Inicia às",
    isVisible: true,
    formatter: ({ startDate }) => moment(startDate).format("HH:mm"),
  },
  {
    accessor: "endDate",
    title: "Finaliza às",
    isVisible: true,
    formatter: ({ endDate }) => moment(endDate).format("HH:mm"),
  },
  {
    accessor: "customer.tradingName",
    title: "Cliente",
    isVisible: true,
    render: ({ customer }) =>
      customer ? (
        <HStack>
          <CustomerLogo boxSize="30px" alt={customer.tradingName} src={customer.logoUrl} />
          <Link as={RouterLink} to={`/customers/edit/${customer._id}`} target="_blank">
            {customer.tradingName}
          </Link>
        </HStack>
      ) : (
        "-"
      ),
    exporter: ({ customer }) => customer?.tradingName || "-",
  },
  {
    accessor: "customer.classification",
    title: "Classificação",
    isVisible: true,
    formatter: ({ customer }) => customer?.classification || "-",
  },
  {
    accessor: "meetingType",
    title: "Tipo de reunião",
    isVisible: true,
    formatter: ({ meetingType }) => meetingType?.title || "-",
  },
  {
    accessor: "mainMeetingRoom",
    title: "Sala de reunião",
    isVisible: true,
    formatter: ({ mainMeetingRoom }) => mainMeetingRoom?.title || "-",
  },
  {
    accessor: "demands",
    title: "Demandas",
    isVisible: true,
    isSortable: false,
    formatter: ({ demands }) => _.map(demands, "title").join(", "),
  },
  {
    accessor: "participants.user",
    title: "Participantes",
    isVisible: true,
    isSortable: false,
    render: ({ participants }) => (
      <HStack>
        <AvatarGroup size="xs" max={2}>
          {_.map(participants, (o) => (
            <Avatar key={o._id} name={o.user?.name} src={o?.user?.avatarUrl} />
          ))}
        </AvatarGroup>
        <Text>{_.map(participants, "user.name").join(", ")}</Text>
      </HStack>
    ),
    exporter: ({ participants }) => _.map(participants, "user.name").join(", "),
  },
  {
    accessor: "customerParticipants.name",
    title: "Participantes/Cliente",
    isVisible: true,
    isSortable: false,
    formatter: ({ customerParticipants }) => _.map(customerParticipants, "name").join(", "),
  },
  {
    accessor: "startedAt",
    title: "Iniciada em",
    isVisible: true,
    formatter: ({ startedAt }) => (startedAt ? moment(startedAt).format("DD/MM/YYYY HH:mm") : "-"),
  },
  {
    accessor: "finishedAt",
    title: "Finalizada em",
    isVisible: true,
    formatter: ({ finishedAt }) => (finishedAt ? moment(finishedAt).format("DD/MM/YYYY HH:mm") : "-"),
  },
  {
    accessor: "durationInMinutes",
    title: "Duração",
    isVisible: true,
    render: ({ durationInMinutes }) => `${durationInMinutes?.toLocaleString() ?? 0} minutos`,
    exporter: ({ durationInMinutes }) => durationInMinutes?.toLocaleString() ?? 0,
  },
  {
    accessor: "createdAt",
    title: "Criado em",
    isVisible: true,
    formatter: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
  },
  {
    accessor: "createdBy.name",
    title: "Criado por",
    isVisible: true,
    formatter: ({ createdBy }) => createdBy?.name || "-",
  },
  {
    accessor: "updatedAt",
    title: "Atualizado em",
    isVisible: true,
    formatter: ({ updatedAt }) => moment(updatedAt).format("DD/MM/YYYY HH:mm"),
  },
];

export default defaultColumns;
