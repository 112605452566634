import React, { createContext, useCallback, useMemo, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Heading,
  HStack,
  Icon,
  IconButton,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import _ from "lodash";
import { api } from "lib";
import { useApiGet, useCustomToast, useDocumentTitle } from "hooks";
import {
  Breadcrumb,
  GridViewerCalendar,
  GridViewerConsultant,
  GridViewerKanban,
  GridViewerMeetingRoom,
  GridViewerToolbar,
  GridViewerWeek,
  PermissionedContainer,
} from "components";
import { statuses } from "consts";
import Filters from "./filters";
import Card from "./card";
import Delayeds from "../delayeds";
import { Content, ContentBody, ContentHeader } from "pages/Private/Container";
import { TbLayoutList } from "react-icons/tb";

export const MeetingsGridContext = createContext();

export const MeetingsGrid = () => {
  useDocumentTitle("Reuniões");
  const [viewMode, setViewMode] = useState();
  const [dateRange, setDateRange] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [query, setQuery] = useState();
  const [data, isLoadingData, refreshData] = useApiGet(
    useMemo(
      () => ({
        path: "/meetings",
        params: {
          query: { ...query, startDate: { $gt: ["@ISODate", startDate] }, endDate: { $lt: ["@ISODate", endDate] } },
          sort: { startDate: 1 },
          perPage: -1,
        },
        options: { isEnabled: _.isObject(query) && startDate && endDate },
      }),
      [query, startDate, endDate]
    )
  );
  const [isLoadingDeleteData, setIsLoadingDeleteData] = useState(false);
  const { isOpen: isOpenDeleteDialog, onOpen: onOpenDeleteDialog, onClose: onCloseDeleteDialog } = useDisclosure();
  const [checkeds, setCheckeds] = useState([]);
  const toast = useCustomToast();

  const handleToolbalChange = useCallback((viewMode, dateRange, startDate, endDate) => {
    setViewMode(viewMode);
    setDateRange(dateRange);
    setStartDate(startDate);
    setEndDate(endDate);
  }, []);

  const handleDeleteData = useCallback(async () => {
    try {
      setIsLoadingDeleteData(true);
      await api.delete("/meetings", { data: checkeds });
      setCheckeds([]);
      refreshData();
    } catch (error) {
      if (error.isHandled) return;
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setIsLoadingDeleteData(false);
      onCloseDeleteDialog();
    }
  }, [checkeds, onCloseDeleteDialog, toast, refreshData]);

  const renderViewModes = useCallback(() => {
    switch (viewMode) {
      case "kanban":
        return (
          <GridViewerKanban
            statuses={statuses.meetings}
            data={data?.data}
            renderItem={(item) => <Card key={item._id} {...item} />}
            isLoading={isLoadingData}
          />
        );
      case "calendar":
        return (
          <GridViewerCalendar
            dateKey="startDate"
            dateRange={dateRange}
            startDate={startDate}
            endDate={endDate}
            data={data?.data}
            renderItem={(item) => <Card key={item._id} {...item} />}
            isLoading={isLoadingData}
          />
        );
      case "week":
        return (
          <GridViewerWeek
            dateKey="startDate"
            dateRange={dateRange}
            startDate={startDate}
            endDate={endDate}
            data={data?.data}
            renderItem={(item) => <Card key={item._id} {...item} />}
            isLoading={isLoadingData}
          />
        );
      case "consultant":
        return (
          <GridViewerConsultant
            dateKey="startDate"
            colKey="participants"
            dateRange={dateRange}
            startDate={startDate}
            endDate={endDate}
            data={data?.data}
            renderItem={(item) => <Card key={item._id} {...item} />}
            isLoading={isLoadingData}
          />
        );
      case "meetingRoom":
        return (
          <GridViewerMeetingRoom
            dateKey="startDate"
            dateRange={dateRange}
            startDate={startDate}
            endDate={endDate}
            data={data?.data}
            renderItem={(item) => <Card key={item._id} {...item} />}
            isLoading={isLoadingData}
          />
        );
    }
  }, [viewMode, dateRange, startDate, endDate, data?.data, isLoadingData]);

  return (
    <MeetingsGridContext.Provider value={{ viewMode, data, isLoadingData, refreshData, setCheckeds, onOpenDeleteDialog }}>
      <Content>
        <ContentHeader>
          <HStack justify="space-between">
            <Breadcrumb items={[{ label: "cadastros" }, { to: "/meetings/grid", label: "reuniões" }]} />
            <HStack>
              <PermissionedContainer required="meetings:create">
                <Button as={RouterLink} to="/meetings/new" size="sm" colorScheme="main">
                  incluir cadastro
                </Button>
              </PermissionedContainer>
              <Tooltip label="Alternar para visualização em lista." placement="left">
                <IconButton size="sm" variant="outline" as={RouterLink} to="/meetings/list" icon={<Icon as={TbLayoutList} />} />
              </Tooltip>
            </HStack>
          </HStack>
          <Heading my="15px" size="md">
            Reuniões
          </Heading>
          <Filters onQuery={setQuery} onRefresh={refreshData} isLoading={isLoadingData} />
          <GridViewerToolbar onChange={handleToolbalChange} />
        </ContentHeader>
        <ContentBody>{renderViewModes()}</ContentBody>
      </Content>
      <AlertDialog isOpen={isOpenDeleteDialog} onClose={onCloseDeleteDialog} isCentered>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Atenção</AlertDialogHeader>
          <AlertDialogBody>Deseja realmente excluir os registros selecionados?</AlertDialogBody>
          <AlertDialogFooter as={HStack} justify="flex-end">
            <Button onClick={onCloseDeleteDialog}>Cancelar</Button>
            <Button colorScheme="red" onClick={handleDeleteData} isLoading={isLoadingDeleteData}>
              Excluir
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <Delayeds />
    </MeetingsGridContext.Provider>
  );
};
