import React, { useContext, useMemo, useEffect, useState, useCallback } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Avatar,
  Box,
  Button,
  Center,
  Grid,
  GridItem,
  HStack,
  Icon,
  IconButton,
  Link,
  Spinner,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import _ from "lodash";
import moment from "moment";
import { FileList, CustomBadge, PermissionedContainer, FileUpload } from "components";
import { useApiGet, useCustomToast } from "hooks";
import { MdAttachFile, MdDelete, MdKeyboardArrowDown, MdKeyboardArrowUp, MdRefresh } from "react-icons/md";
import { TbCalendar } from "react-icons/tb";
import MeetingsDetailsContext from "../context";
import { api } from "lib";
import { AppContext } from "AppProvider";

const Files = ({ item, refreshTasks }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box p="15px">
      <HStack justifyContent="space-between">
        <Text fontSize="sm">{_.size(item.files)} arquivos adicionados</Text>
        <Button size="xs" colorScheme="main" rightIcon={<Icon as={MdAttachFile} />} onClick={onOpen}>
          incluir arquivos
        </Button>
      </HStack>
      <FileList path="/files" data={item.files} onChange={refreshTasks} />
      <FileUpload
        path="/files"
        ownerRef="Task"
        owner={item._id}
        isOpen={isOpen}
        onClose={onClose}
        onChange={refreshTasks}
        isReplaceMode={true}
      />
    </Box>
  );
};

const Tasks = () => {
  const { _id } = useParams();
  const { isTabModeV } = useContext(AppContext);
  const { data, setCounters, setIsLoadingCounters, refreshData } = useContext(MeetingsDetailsContext);
  const [tasks, isLoadingTasks, refreshTasks] = useApiGet(
    useMemo(
      () => ({
        path: "/tasks",
        params: { query: { "meeting._id": ["@ObjectId", _id] }, withFiles: true, perPage: -1, isAutocomplete: true },
        options: { isEnabled: _.isString(_id) },
      }),
      [_id, data?.status]
    )
  );
  const [isOpen, setIsOpen] = useState({});
  const [isLoadingDeleteData, setIsLoadingDeleteData] = useState(false);
  const [deleteSelected, setDeleteSelected] = useState();
  const toast = useCustomToast();

  useEffect(() => {
    setCounters((state) => ({ ...state, tasks: tasks?.size ?? 0 }));
  }, [setCounters, tasks?.size]);

  useEffect(() => {
    setIsLoadingCounters((state) => ({ ...state, tasks: isLoadingTasks }));
  }, [setIsLoadingCounters, isLoadingTasks]);

  const handleDeleteData = useCallback(async () => {
    try {
      setIsLoadingDeleteData(true);
      await api.delete("/tasks", { data: [deleteSelected] });
      setDeleteSelected();
      refreshTasks();
      refreshData();
    } catch (error) {
      if (error.isHandled) return;
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setIsLoadingDeleteData(false);
    }
  }, [deleteSelected, toast, refreshTasks, refreshData]);

  return (
    <>
      <HStack mb="20px">
        <Text flex="1" fontSize="sm" fontWeight="semibold">
          {_.size(tasks?.data)} registros adicionados
        </Text>
        <IconButton size="sm" variant="outline" icon={<Icon as={MdRefresh} />} isLoading={isLoadingTasks} onClick={refreshTasks} />
      </HStack>

      <VStack align="stretch" spacing={2}>
        {_.map(tasks?.data, (item) => (
          <Box key={item._id} role="group" position="relative" borderWidth="1px" borderRadius="md" overflow="hidden">
            <HStack p="10px" _light={{ bg: "gray.50" }} _dark={{ bg: "gray.900" }}>
              <Grid flex="1" templateColumns="repeat(12, 1fr)" gap={2}>
                <GridItem colSpan={{ base: 12, lg: 1 }} borderWidth="1px" borderRadius="lg" p="10px">
                  <Text fontSize="xs" fontWeight="semibold">
                    NID
                  </Text>
                  <Link as={RouterLink} to={`/tasks/edit/${item._id}`} target="_blank" fontSize="sm">
                    #{item.nid}
                  </Link>
                </GridItem>
                <GridItem colSpan={{ base: 12, lg: 1 }} borderWidth="1px" borderRadius="lg" p="10px">
                  <Text fontSize="xs" fontWeight="semibold">
                    Status
                  </Text>
                  <CustomBadge.Tasks.Status status={item.status} />
                </GridItem>
                <GridItem colSpan={{ base: 12, lg: 1 }} borderWidth="1px" borderRadius="lg" p="10px">
                  <Text fontSize="xs" fontWeight="semibold">
                    Referência
                  </Text>
                  <Text fontSize="sm">{moment(item.referenceDate).format("MM/YYYY")}</Text>
                </GridItem>
                <GridItem colSpan={{ base: 12, lg: 1 }} borderWidth="1px" borderRadius="lg" p="10px">
                  <Text fontSize="xs" fontWeight="semibold">
                    Vencimento
                  </Text>
                  <Text fontSize="sm">{moment(item.dueDate).format("DD/MM/YYYY")}</Text>
                </GridItem>
                <GridItem colSpan={{ base: 12, lg: 3 }} borderWidth="1px" borderRadius="lg" p="10px">
                  <Text fontSize="xs" fontWeight="semibold">
                    Time
                  </Text>
                  <Text fontSize="sm">{item.team?.title ?? "-"}</Text>
                </GridItem>
                <GridItem colSpan={{ base: 12, lg: 3 }} borderWidth="1px" borderRadius="lg" p="10px">
                  <Text fontSize="xs" fontWeight="semibold">
                    Demanda
                  </Text>
                  <Text fontSize="sm">{item.demand?.title}</Text>
                </GridItem>
                <GridItem colSpan={{ base: 12, lg: 2 }} borderWidth="1px" borderRadius="lg" p="10px">
                  <Text fontSize="xs" fontWeight="semibold">
                    Responsável
                  </Text>
                  <HStack>
                    <Avatar size="xs" name={item.responsible?.name} src={item.responsible?.avatarUrl} />
                    <Text fontSize="sm">{item.responsible?.name ?? "-"}</Text>
                  </HStack>
                </GridItem>
              </Grid>
              <VStack>
                <PermissionedContainer required="tasks:delete">
                  <IconButton
                    size="xs"
                    colorScheme="red"
                    variant="outline"
                    icon={<Icon as={MdDelete} />}
                    onClick={() => setDeleteSelected(item._id)}
                  />
                </PermissionedContainer>
                <IconButton
                  size="xs"
                  icon={<Icon as={isOpen[item._id] ? MdKeyboardArrowUp : MdKeyboardArrowDown} />}
                  onClick={() => setIsOpen((state) => ({ ...state, [item._id]: !state[item._id] }))}
                />
              </VStack>
            </HStack>
            {isTabModeV && isOpen[item._id] && _.size(item.files) >= 1 && <Files {...{ item, refreshTasks }} />}
          </Box>
        ))}
      </VStack>

      {isLoadingTasks ? (
        <Center p="40px">
          <Spinner />
        </Center>
      ) : (
        _.size(tasks?.data) === 0 && (
          <Center paddingTop="40px" paddingBottom="20px">
            <Box textAlign="center">
              <Icon as={TbCalendar} boxSize={20} marginBottom="10px" />
              <Text fontSize="lg" fontWeight="bold">
                Nenhuma tarefa adicionada
              </Text>
              <Text fontSize="sm">Ainda não existem tarefas para o mês de agendamento desta reunião.</Text>
            </Box>
          </Center>
        )
      )}

      <AlertDialog isOpen={_.isString(deleteSelected)} onClose={() => setDeleteSelected()} isCentered>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Atenção</AlertDialogHeader>
          <AlertDialogBody>Deseja realmente excluir os registros selecionados?</AlertDialogBody>
          <AlertDialogFooter as={HStack} justify="flex-end">
            <Button onClick={() => setDeleteSelected()}>Cancelar</Button>
            <Button colorScheme="red" onClick={handleDeleteData} isLoading={isLoadingDeleteData}>
              Excluir
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default Tasks;
