import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightAddon,
  InputRightElement,
} from "@chakra-ui/react";
import { usePermissioned } from "hooks";
import { InputCurrency, SingleDatePicker } from "components";
import MeetingsDetailsContext from "../context";
import ReactInputMask from "react-input-mask";
import { TbCalendar, TbClock } from "react-icons/tb";
import Schedules from "./schedules";
import Rescheduled from "./rescheduled";

const DateTime = () => {
  const { _id } = useParams();
  const { formData: parentFormData, setFormData: setParentFormData, formErrors } = useContext(MeetingsDetailsContext);
  const [formData, setFormData] = useState({});
  const isAllowedUpdateStartAndEndDate = usePermissioned("meetings:updateStartAndEndDate");
  const isDisabledStartAndEndDate = useMemo(
    () => (_.isString(_id) ? isAllowedUpdateStartAndEndDate === false : false),
    [_id, isAllowedUpdateStartAndEndDate]
  );

  useEffect(() => {
    setFormData(() => ({
      startDate: moment(parentFormData.startDate).format("HH:mm"),
      endDate: moment(parentFormData.endDate).format("HH:mm"),
    }));
  }, [parentFormData.startDate, parentFormData.endDate]);

  const handleBlurStartDate = useCallback(
    ({ target }) => {
      setParentFormData((state) => {
        const [hours, minutes] = target.value.split(":").map((v) => parseInt(v));
        const startDate = moment(state.startDate).startOf("day").add({ hours, minutes }).toDate();
        const endDate = moment(startDate).add(parentFormData.predictedDurationInMinutes, "minutes").toDate();
        return { ...state, startDate, endDate };
      });
    },
    [parentFormData.predictedDurationInMinutes]
  );

  const handleBlurEndDate = useCallback(
    ({ target }) => {
      setParentFormData((state) => {
        const [hours, minutes] = target.value.split(":").map((v) => parseInt(v));
        const endDate = moment(state.startDate).startOf("day").add({ hours, minutes }).toDate();
        return { ...state, endDate };
      });
    },
    [parentFormData.predictedDurationInMinutes]
  );

  return (
    <Grid templateColumns="repeat(12, 1fr)" gap={4}>
      <GridItem colSpan={{ base: 12, lg: 4 }}>
        <FormControl isRequired={true} isInvalid={formErrors.predictedDurationInMinutes}>
          <FormLabel fontSize="sm">Duração prevista</FormLabel>
          <InputGroup>
            <Input
              as={InputCurrency}
              precision="0"
              value={parentFormData.predictedDurationInMinutes}
              onChange={(predictedDurationInMinutes) => setParentFormData((state) => ({ ...state, predictedDurationInMinutes }))}
            />
            <InputRightAddon fontSize="sm">minutos</InputRightAddon>
          </InputGroup>
          <FormErrorMessage>{formErrors.predictedDurationInMinutes}</FormErrorMessage>
        </FormControl>
      </GridItem>
      <GridItem colSpan={{ base: 12, lg: 4 }}>
        <FormControl flex="1" isRequired={true}>
          <HStack mb="0.3em" justifyContent="space-between">
            <FormLabel fontSize="sm" m="0">
              Data
            </FormLabel>
            <Rescheduled />
          </HStack>
          <InputGroup>
            <SingleDatePicker
              usePortal={true}
              // minDate={moment().startOf("day").toDate()}
              date={parentFormData.startDate}
              onDateChange={(startDate) =>
                setParentFormData((state) => ({
                  ...state,
                  startDate,
                  endDate: moment(startDate).add(parentFormData.predictedDurationInMinutes, "minutes").toDate(),
                }))
              }
              disabled={isDisabledStartAndEndDate}
            />
            <InputRightElement>
              <Icon as={TbCalendar} />
            </InputRightElement>
          </InputGroup>
        </FormControl>
      </GridItem>
      <GridItem colSpan={{ base: 12, lg: 4 }}>
        <FormControl isRequired={true} isInvalid={formErrors.startDate || formErrors.endDate}>
          <HStack mb="0.3em" justifyContent="space-between">
            <FormLabel fontSize="sm" m="0">
              Horário
            </FormLabel>
            <Schedules />
          </HStack>
          <HStack spacing="1px">
            <InputGroup>
              <InputLeftElement fontSize="sm">de</InputLeftElement>
              <Input
                as={ReactInputMask}
                mask="99:99"
                borderRightRadius="0"
                value={formData.startDate}
                onChange={({ target }) => setFormData((state) => ({ ...state, startDate: target.value }))}
                onBlur={handleBlurStartDate}
                isDisabled={isDisabledStartAndEndDate}
              />
              <InputRightElement>
                <Icon as={TbClock} />
              </InputRightElement>
            </InputGroup>
            <InputGroup>
              <InputLeftElement fontSize="sm">às</InputLeftElement>
              <Input
                as={ReactInputMask}
                mask="99:99"
                borderLeftRadius="0"
                value={formData.endDate}
                onChange={({ target }) => setFormData((state) => ({ ...state, endDate: target.value }))}
                onBlur={handleBlurEndDate}
                isDisabled={isDisabledStartAndEndDate}
              />
              <InputRightElement>
                <Icon as={TbClock} />
              </InputRightElement>
            </InputGroup>
          </HStack>
          <FormErrorMessage>{formErrors.startDate || formErrors.endDate}</FormErrorMessage>
        </FormControl>
      </GridItem>
    </Grid>
  );
};

export default DateTime;
